import React, { Component } from 'react';
import NavigationBar from '../components/NavigationBar';
import FooterBar from '../components/FooterBar';

class About extends Component {
    static displayName = 'page-about';

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        document.title = "About"
    }

    render() {
        return (
            <div>
                <div className="main-content">
                    <NavigationBar
                        color="is-warning"
                        pathname={this.props.location.pathname}
                        title="Sobre"
                        subtitle="Um pouco sobre mim" />

                    <section className="section">
                        <div className="container">
                            <h1 className="title">Página de Sobre mim</h1>
                        </div>
                    </section>
                </div>

                <FooterBar />
            </div>
        );
    }
}

export default About;