import React, { Component } from 'react';
import NavigationBar from '../components/NavigationBar';
import FooterBar from '../components/FooterBar';

class Portfolio extends Component {
    static displayName = 'page-portfolio';

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        document.title = "Portfolio"
    }

    render() {
        return (
            <div>
                <div className="main-content">
                    <NavigationBar
                        color="is-primary"
                        pathname={this.props.location.pathname}
                        title="Portfolio"
                        subtitle="Software Developer" />

                    <section className="section">
                        <div className="container">
                            <h1 className="title">Página de portfolio</h1>
                        </div>
                    </section>
                </div>

                <FooterBar />
            </div>
        );
    }
}

export default Portfolio;