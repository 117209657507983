import React, { Component } from 'react';
import NavigationBar from '../components/NavigationBar';
import FooterBar from '../components/FooterBar';

class Blog extends Component {
    static displayName = 'page-blog';

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        document.title = "Blog"
    }

    render() {
        return (
            <div>
                <div className="main-content">
                    <NavigationBar
                        color="is-info"
                        pathname={this.props.location.pathname}
                        title="Blog"
                        subtitle="Compartinhando conhecimento" />

                    <section className="section">
                        <div className="container">
                            <h1 className="title">Página de Blog</h1>
                        </div>
                    </section>
                </div>

                <FooterBar />
            </div>
        );
    }
}

export default Blog;