import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Portfolio from './pages/Portfolio';
import Blog from './pages/Blog';
import About from './pages/About';
import Contact from './pages/Contact';
import './css/style.css';
import 'react-bulma-components/dist';
import 'react-bulma-components/dist/react-bulma-components.min.css';

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" render={() => (<Redirect to="/portfolio" />)} />
                    <Route exact path="/portfolio" component={Portfolio} />
                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/contact" component={Contact} />
                    <Route path="*" render={() => (<Redirect to="/portfolio" />)} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;