import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Navbar } from 'react-bulma-components';

class NavigationBar extends Component {
    static displayName = 'navbar-component';

    constructor(props) {
        super(props);

        this.state = {
            navbarActive: false
        };
    }

    burgerClick = () => {
        const { navbarActive } = this.state;
        this.setState({ navbarActive: !navbarActive });
    }

    render() {
        return (
            <div>
                <section className={`hero is-medium ${this.props.color} is-bold`}>
                    <div className="hero-head">
                        <Navbar active={this.state.navbarActive}>
                            <Navbar.Brand>
                                <Link to="/portfolio" className="navbar-item">
                                    <img src="https://bulma.io/images/bulma-logo-white.png" alt="imagem do logo" />
                                </Link>
                                <Navbar.Burger onClick={this.burgerClick} />
                            </Navbar.Brand>
                            <Navbar.Menu>
                                <div className="navbar-end">
                                    <Link to="/portfolio" className={this.props.pathname === "/portfolio"
                                        ? "navbar-item is-active" : "navbar-item"}>Portfolio</Link>
                                    <Link to="/blog" className={this.props.pathname === "/blog"
                                        ? "navbar-item is-active" : "navbar-item"}>Blog</Link>
                                    <Link to="/about" className={this.props.pathname === "/about"
                                        ? "navbar-item is-active" : "navbar-item"}>Sobre</Link>
                                    <Link to="/contact" className={this.props.pathname === "/contact"
                                        ? "navbar-item is-active" : "navbar-item"}>Contato</Link>
                                </div>
                            </Navbar.Menu>
                        </Navbar>
                    </div>
                    <div className="hero-body">
                        <div className="container">
                            <h1 className="title">{this.props.title}</h1>
                            <h2 className="subtitle">{this.props.subtitle}</h2>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default NavigationBar;