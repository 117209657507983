import React from 'react';

const FooterBar = () => {
    return (
        <div>
            <footer className="footer">
                <div className="content has-text-centered">
                    <p className="copyright">© 2019 - Emanuel Andrade</p>
                </div>
            </footer>
        </div>
    );
}

export default FooterBar;