import React, { Component } from 'react';
import NavigationBar from '../components/NavigationBar';
import FooterBar from '../components/FooterBar';

class Contact extends Component {
    static displayName = 'page-contact';

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        document.title = "Contact"
    }

    render() {
        return (
            <div>
                <div className="main-content">
                    <NavigationBar
                        color="is-dark"
                        pathname={this.props.location.pathname}
                        title="Contato"
                        subtitle="Será um prazer atendê-lo" />

                    <section className="section">
                        <div className="container">
                            <h1 className="title">Página de contato</h1>
                        </div>
                    </section>
                </div>

                <FooterBar />
            </div>
        );
    }
}

export default Contact;